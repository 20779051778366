import React, { createContext, useReducer } from 'react'
import OrderReducer from '../reducers/OrderReducer'

export const OrderContext = createContext();

const OrderContextProvider = (props) => {
    const [orderContext, orderDispatch] = useReducer(OrderReducer , {
      cabinList: [],
      blockLines: [],
      selectedCabinId: 0,
      orderList: [],
      selected: 0,
      days: [],
      selectedDate: null,
      selectedOrderId: 0,
      dataLoadFailed: false,
    });

    return (
        <OrderContext.Provider value={{ orderContext, orderDispatch }}>
            {props.children}
        </OrderContext.Provider>
    ); 
}

export default OrderContextProvider;