import React, {useState, useEffect, useContext} from 'react'
import L from 'leaflet'
import { OrderContext } from '../contexts/OrderContext'
import { UserContext } from '../contexts/UserContext'
import { useHistory } from "react-router-dom";
import {Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, TextField, Radio, RadioGroup, FormControl, Modal, Backdrop, Fade, InputLabel, Select, MenuItem, Typography} from '@material-ui/core';

import {completeOrder, getOrders, getWeekList, getAllCabins, getAllScBlockLines, sendSmsToOne, sendSmsToAll, orderSnowClearing, getAllClubs } from './utils/Server'
import {splitt, getTimeFormat, getDateFormat, multiSplitt} from './utils/Library'
import NavFooter from './NavFooter'

import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

 const SnowClearingMap = () => {

    let history = useHistory();
    const classes = useStyles();

    // Local state
    const [state, setState] = useState({
        mapHolder: null,
        cabinLayerGroup: null,
        tractorLayerGroup: null,
        //lat: 59.7747669,
        //lng: 7.3369551,
        zoom: 16,
        showLocation: false,
        content: 'ORDERS',
        screenRefreshRate: 5000,
        returnMessage: '',
        showSmsAll: false,
        showSmsSingle: false,
        showOrderDia: false,
        smsMessage: ''        
    });

    /*const localeOptions = Object.keys(locales)
        .map(key => ({
        value: key,
        label: `${key} - ${nameMapper[key] || ''}`
        }))
        .filter(item => nameMapper[item.value]);*/
  
    const [locale] = useState('nb');
    const [orderDates, setOrderDates] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);    
    //const [date, setDate] = useState(null);

    // Shared context
    const { orderContext, orderDispatch } = useContext(OrderContext);
    const { userContext, userDispatch } = useContext(UserContext);

    // Icons
    let greenIcon = L.icon({
        iconUrl: require("../images/Ball-Right-Green.png"),
        iconSize: [38, 38],
        iconAnchor: [20, 40],
        popupAnchor: [0, 0]        
    });        
    let redIcon = L.icon({
        iconUrl: require("../images/Red-Marker.png"),
        iconSize: [38, 38],
        iconAnchor: [20, 40],
        popupAnchor: [0, 0]        
    });        
    let purpleIcon = L.icon({
        iconUrl: require("../images/Purple-Marker.png"),
        iconSize: [38, 38],
        iconAnchor: [20, 40],
        popupAnchor: [0, 0]        
    });        
    let cabinIcon = L.icon({
        iconUrl: require("../images/cabinTransparent.png"),
        iconSize: [38, 38],
        iconAnchor: [20, 40],
        popupAnchor: [0, 0]        
    });                

    // Tractor marker
    let tractorIcon = L.icon({
        iconUrl: require("../images/circleMarker.png"),
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [-3, -76]
    });

    /////////////////////
    // Server interaction
    /////////////////////


    ////////////////////
    // useEffect area
    ////////////////////
    useEffect(() => {
        // Get clubs
        getAllClubs(userContext.companyId, userContext, userDispatch, orderDispatch);
    }, [])


    useEffect(() => {
        if(userContext.currentClubId!=0) {

            // Get week overview
            getWeekList(userContext.currentClubId, userContext, orderDispatch);

            // Get all cabins
            getAllCabins(userContext.currentClubId, userContext, orderDispatch);        

            // Get all "snow blocking lines"
            getAllScBlockLines(userContext.currentClubId, userContext, orderDispatch);

            // Find club information
            const club = userContext.clubs.find(club => club.id==userContext.currentClubId)
            const mapCenter = splitt(club.mapCenter);

            // Map update or setup
            if(state.mapHolder) {
                // Map is already initialized ==> Update center and location
                state.mapHolder.setView(mapCenter[0], state.zoom)
            } else {
                // Map is not initialized ==> Initialize map 
                // Setup map - this creates the map instance

                //let map = L.map('snowClearingMap').setView([state.lat, state.lng], state.zoom);
                let map = L.map('snowClearingMap').setView(mapCenter[0], state.zoom);
                L.tileLayer('https://opencache2.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}', {
                            attribution: '<a href="http://www.kartverket.no/">Kartverket</a>',
                            maxZoom: 20
                        }).addTo(map);
                let cabinLayerGroup = L.layerGroup().addTo(map);
                let tractorLayerGroup = L.layerGroup().addTo(map);

                // Use GeoLocation / GPS position
                //map.locate({watch: true, setView: true, maximumAge: 5000 });
                map.on('locationfound', onLocationFound);
                map.on('locationerror', onLocationError);

                // Save map and layers to local state for convenience
                setState({
                    ...state,
                    mapHolder: map,
                    cabinLayerGroup: cabinLayerGroup,
                    tractorLayerGroup: tractorLayerGroup
                });
            }
        }
    }, [userContext.currentClubId]);

    // Handle data load errors & redirect to login page
    useEffect(() => {
        // If dataload failed, re-route to login page
        if(orderContext.dataLoadFailed) {
            clearInterval();
            //console.log("Data load failed");

            // Redirect to login page
            orderDispatch({type:'DATA_LOAD_FAILED', dataLoadFailed: false});    
            history.push('/');
        }
    }, [orderContext.dataLoadFailed]);

    // Handle refresh
    useEffect(() => {
        if(userContext.currentClubId!=0) {
            //console.log("Timer started");
            const timer = setInterval(() => refreshOrders(), state.screenRefreshRate);
            return () => {
                clearInterval(timer)
                //console.log("Timer stopped");
            };            
        }
    },[state.screenRefreshRate, state.content, orderContext.selectedDate]);



    ////////////////////
    // Action handlers
    ////////////////////    

    // Mark a snow clearing order as completed
    const setSelectedOrder = (orderId) => {
        orderDispatch({ type: 'SET_SELECTED_ORDER', orderId: orderId});
    };

    // Mark the selected date
    const setSelectedDate = (selectedDate) => {
        //console.log(selectedDate);
        orderDispatch({ type: 'SET_SELECTED_DATE', selectedDate: selectedDate});
    };

    // Capture the selected cabin
    const setSelectedCabin = (cabinId) => {
        orderDispatch({ type: 'SET_SELECTED_CABIN', cabinId: cabinId});
    }

    //
    // Show GPS location
    // Called from the map.watch function triggered 
    //
    const onLocationFound = (e) => {
        //let radius = e.accuracy;
        if(state.showLocation) {
            if(e.latlng) {
                state.tractorLayerGroup.clearLayers();
                L.marker(e.latlng, {icon: tractorIcon}).addTo(state.tractorLayerGroup).bindPopup("Traktor");
            }
        }
        
    }

    const onLocationError = (e) => {
        alert(e.message);
    }

    //
    // Handle changes to the "showLocation" switch
    //
    const handleShowLocationChange = (e) => {
        setState({...state, showLocation: e.target.checked})
        if(e.target.checked) {
            state.mapHolder.locate({watch: true, setView: true, timeout: 20000 });
            state.mapHolder.on('locationfound', onLocationFound);
        } else {
            state.mapHolder.stopLocate();
        }
    }; 

    //
    // Complete order handler
    // 
    const handleClick = (e) => {
        let curOrder = orderContext.orderList.filter(( order ) => {
            if(order.id == e.currentTarget.value) {
                return order;
            } else return null;
        });  
        curOrder[0].returnMessage = state.returnMessage;
        completeOrder(userContext.currentClubId, curOrder[0], userContext, orderDispatch);
        setState({
            ...state,
            returnMessage: ''
        })
    }

    //
    // Clear selection handler
    const handleClearClick = (e) => {
        setSelectedOrder(0);
        setSelectedCabin(0);
        setState({...state, showSmsAll: false, showSmsSingle: false, showOrderDia: false});
    }

    //
    // Select day button handler
    //
    const handleDayButtonClick = (e) => {
        setSelectedDate(e.currentTarget.value);
        getOrders(userContext.currentClubId, e.currentTarget.value, userContext, orderDispatch);
    }

    //
    // Send SMS to all cabins
    //
    const handleSendSMSToAllClick = (e) => {
        //console.log('Sender SMS ', state.smsMessage, " to all cabin owners");
        // Call server here
        sendSmsToAll(userContext.currentClubId, state.smsMessage, userContext);

        // Close message window and clear message
        setState({...state, showSmsAll: !state.showSmsAll, smsMessage: ''});
    }

    // 
    // Send SMS to one cabin owner
    //
    const sendPersonalSMS = (e) => {
        //console.log("Sending sms ", state.smsMessage, " to owner of cabin ",orderContext.selectedCabinId);
        // Call server here
        sendSmsToOne(userContext.currentClubId, orderContext.selectedCabinId, state.smsMessage, userContext);

        // Reset 
        setState({...state, smsMessage: ''});
        setSelectedOrder(0);
        setSelectedCabin(0);
        handleClearClick();
    }


    //
    // Handle input in the return message Textfield
    //
    const handleTextInput = (e) => {
        setState({
             ...state,
            returnMessage: e.currentTarget.value
        });
    }

    //
    // Handle SMS input text
    const handleSMSTextInput = (e) => {
        setState({
            ...state,
            smsMessage: e.currentTarget.value
        })
    }

    // Flip switch ORDERS vs CABINS content
    const handleContentChange = (e) => {
        setState({...state, content: e.target.value});
    }

    // Save snowclearing orders
    const saveScOrder = (e) => {
        const order = {
            memberId: e.currentTarget.value,
            propertyId: orderContext.selectedCabinId,
            fromDate: getDateFormat(orderDates[0].startDate),
            fromTime: '18:00',
            toDate: getDateFormat(orderDates[0].endDate),
            comment: 'Bestilt av brøyter'
        }
        orderSnowClearing(userContext.currentClubId, order, userContext);
        handleClearClick();
    }

    // Refresh function - called from "setInterval"
    const refreshOrders = () => {
        //console.log("Timer ran! ", state.content, orderContext.selectedDate);
        if(state.content === 'ORDERS' && userContext.jwt && userContext.currentClubId!=0) {
            getWeekList(userContext.currentClubId, userContext, orderDispatch);
            if(orderContext.selectedDate) {
                getOrders(userContext.currentClubId, orderContext.selectedDate, userContext, orderDispatch);
            }             
        }
    }

    // Selecte a club
    const handleSelectClub = (e) => {
        //console.log("Club change ", e.target.value)
        userDispatch({ type: 'SET_CURRECT_CLUB', clubId: e.target.value })
    }


    //
    // Screen / map setup
    //

    // HVA ER DETTE, ORG HVORFOR STÅR DETTE HER?
    orderContext.orderList.sort();

    // Clear all markers
    if(state.cabinLayerGroup) {
        state.cabinLayerGroup.clearLayers();
    }


    ///////////////////////////////////////////////////
    // Presentation area
    ///////////////////////////////////////////////////

    //
    // Loop through the order list to place markers 
    //
    const orderMarkers = (orderContext.orderList.length && state.content==='ORDERS') ? (
        // Loop throug all orders
        orderContext.orderList.map(order => {

            // Choose icon based on status
            let icon = redIcon;
            if(order.late === 1) { // Ordered too late
                icon = purpleIcon;
            }
            if(order.completed === 1) { // Order completed
                icon = greenIcon;
            }

            // Marker for each order on the map
            if(order.lat && order.lng && state.mapHolder && state.cabinLayerGroup) {
                let m = L.marker([order.lat, order.lng], {icon: icon} ).addTo(state.cabinLayerGroup).on('click', (e) => {
                    setSelectedOrder(e.target.props.id);
                    setState({
                        ...state,
                        returnMessage: ''
                    })
                });
                m.props = {id: order.id};
            } 
            // Mark snow clearing area
            if(state.mapHolder && state.cabinLayerGroup) {
                // Permanent places
                if(order.polyPerm) {
                    let latlngP = order.polyPerm;
                    let newlatlngP = multiSplitt(latlngP);
                    let polygonPerm = L.polygon(newlatlngP, {color: 'blue'}).bindPopup('Faste plasser').addTo(state.cabinLayerGroup);
                    //var seeArea = L.GeometryUtil.geodesicArea(polygonPerm.getLatLngs()[0]); 
                    //console.log(seeArea);
                }
                // Extra places
                if(order.polyExtra) {
                    let latlngE = order.polyExtra;
                    let newlatlngE = multiSplitt(latlngE);
                    let polygonExtra = L.polygon(newlatlngE, {color: 'purple'}).bindPopup('Ekstraplasser').addTo(state.cabinLayerGroup);
                }
            }

            // If an order is selected, show the order details
            if(orderContext.selectedOrderId != 0) {

                // Setup: Determine if an action button should be displayed (button or checkmark)
                let actn = order.completed==1 ? (
                    <DoneOutlineIcon color='primary'></DoneOutlineIcon>
                ) : (
                        <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={order.id} onClick={(e) => handleClick(e)} > Utført </Button>     
                );

                // Setup: Clear selection button 
                let clearAct = orderContext.selected==order.id ? (
                    <Button variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
                ) : (null);

                // Setup: Input or display response from snow clearing crew
                let commentField =  <TextField
                                        id="respons"
                                        label="Melding fra brøyter"
                                        placeholder=""
                                        multiline
                                        variant="outlined"
                                        onChange={(e) => handleTextInput(e)}
                                        value={order.returnMessage}
                                    />
                if (order.completed===1 && order.returnMessage!=="") {
                    commentField = <span>Tilbakemelding: {order.returnMessage}<br/></span>
                }
                if (order.completed===1 && !order.returnMessage!=="") {
                    commentField = null;
                }
                
                // Setup: Display Order completion date and time
                let completionDate = order.completed == 1 ? (
                    <span>Utført: {getTimeFormat(order.completedDate)}<br/></span>
                ):(
                    null
                );

                // Setup: Ordered after the deadline
                let tooLateMessage = order.late === 1 ? (
                    <span className="lateMarker">Bestilt etter fristen<br/></span>
                ):(
                    null
                );

                // Display the seleccted order
                if(orderContext.selected===order.id) {
                    let dtStr = getTimeFormat(order.regTime);
                    //let dt = new Date(order.regTime);
                    //let dtStr = ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear() + ' ' + ('0'+dt.getHours()).slice(-2)+':'+('0'+dt.getMinutes()).slice(-2);
                    return (
                        <Card className="sc-card" key={order.id}>
                            <CardContent className="sc-cardContent">
                                <p><b className="sc-head">{order.address}</b><br/>
                                Ankomst {order.fromDate} {order.fromTime}<br/>
                                Avreise {order.toDate}<br/>
                                Mobil: {order.mobil}<br/>
                                Eier: {order.firstName} {order.lastName} {order.companyName}<br/>
                                Ekstraplasser: {order.noExtraParking}<br/>
                                Bestilt: {dtStr}<br/>
                                {tooLateMessage}
                                {completionDate}
                                {order.comment}<br/>
                                {commentField}</p>
                                {actn}
                            </CardContent>
                            {clearAct}
                        </Card>
                        )
                    } else {
                        // Current order not selected
                        return null;
                    }
                } else {
                    // No orders selected
                    return null;
                }
            } 
        )
    ):(
        null  
    );

    //
    // Loop through the week list
    //
    let row = 0;
    const dayButtons = (orderContext.days.length && state.content==='ORDERS') ? (
        // Loop throug the days
        orderContext.days.map(day => {
            row++;  
            let dt = new Date(day.weekdate);
            let dtString = day.weekDay + ' / ' + dt.getDate() + '.' + (dt.getMonth()+1);
            let btnDateKey = ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear();
            return  (
                <div className="center weekButtons" key={row}>
                    <Button variant="contained" color="primary" fullWidth={true} value={btnDateKey} onClick={(e) => handleDayButtonClick(e)} > {dtString} - {day.cnt} </Button>
                </div>
            )
        })
    ) : (
        null           
    );

    // Cabin markers
    const cabinMarkers = (orderContext.cabinList.length && state.content==='CABINS') ? (
        // Loop throug all orders
        orderContext.cabinList.map(cabin => {
            // Marker for each cabin on the map 
            if(cabin.latitude && cabin.longitude && state.mapHolder && state.cabinLayerGroup) {
                let m = L.marker([cabin.latitude, cabin.longitude], {icon: cabinIcon} ).addTo(state.cabinLayerGroup).on('click', (e) => {
                    orderDispatch({type:'SET_SELECTED_CABIN', cabinId: e.target.props.id});
                    //console.log('Valgt ', e.target.props.id);
                });
                m.props = {id: cabin.id};
            } 

            ////////////////////////////////////////////////
            // This is a test
            ////////////////////////////////////////////////

            // Mark snow clearing area
            if(state.mapHolder && state.cabinLayerGroup) {
                // Permanent places
                if(cabin.polyPerm) {
                    let latlngP = cabin.polyPerm;
                    let newlatlngP = multiSplitt(latlngP);
                    //console.log(cabin.id, newlatlngP);
                    let polygonPerm = L.polygon(newlatlngP, {color: 'blue'}).bindPopup('Faste plasser').addTo(state.cabinLayerGroup);
                    /*try{
                        //let seeArea = L.GeometryUtil.geodesicArea(newlatlngP); 
                        //console.log(seeArea);    
                    } catch(err) {
                        console.log(err)
                    }*/
                }
                // Extra places
                if(cabin.polyExtra) {
                    let latlngE = cabin.polyExtra;
                    let newlatlngE = multiSplitt(latlngE);
                    let polygonExtra = L.polygon(newlatlngE, {color: 'purple'}).bindPopup('Ekstraplasser').addTo(state.cabinLayerGroup);
                }
            }



            ////////////////////////////////////////////////
            // End test
            ////////////////////////////////////////////////



            const smsReceiver = "Send SMS til " + cabin.firstname + " " + cabin.lastname;
            const orderHeader = "Bestill brøyting for "+ cabin.firstname + " " + cabin.lastname;

            // Show the details about the selected cabin 
            const basicPart = (
                <Card className="sc-card" key={cabin.id}>
                    <CardContent className="sc-cardContent">
                        <p><b className="sc-head">{cabin.address}</b><br/>
                        Eier: {cabin.firstname} {cabin.lastname} {cabin.companyName}<br/>     
                        Mobil: {cabin.mobil}<br/>
                        Ekstraplasser: {cabin.extraParking}<br/>
                        </p>
                    </CardContent>    
                </Card>                   
            );

            // Handle SMS message button and window for a single cabin
            // If the button has been pressen, show the modal window
            // If not, just show the button
            const smsPart = state.showSmsSingle ? (
                <React.Fragment key={1}>
                    <Modal
                        aria-labelledby="Send SMS til en hytte"
                        aria-describedby="Send SMS til en hytte"
                        className={classes.modal}
                        open={state.showSmsSingle}
                        onClose={(e) => setState({...state, showSmsSingle: !state.showSmsSingle})}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={state.showSmsSingle}>
                            <div className={classes.paper}>
                                <Card className="sc-card" key={2}>
                                    <CardHeader title={smsReceiver} />
                                    <CardContent className="sc-cardContent" style={{alignContent: "center"}}>                
                                        <TextField
                                            id="smsPerson"
                                            label="SMS melding"
                                            placeholder=""
                                            multiline
                                            rows="4"
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => handleSMSTextInput(e)}
                                            value={state.smsMessage}
                                        />
                                        <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={cabin.id} onClick={(e) => sendPersonalSMS(e)} > Send SMS </Button>
                                    </CardContent>
                                    <Button variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
                                </Card>
                            </div>
                        </Fade>
                    </Modal>
                </React.Fragment>
            ) : (
                <Button variant="contained" color="primary" type="submit" fullWidth id="btn" style={{margin: "5px"}} value={cabin.id} onClick={(e) => setState({...state, showSmsSingle: !state.showSmsSingle})} > Send SMS </Button>
            );

            // Snow clearing order - modal window
            const orderPart = state.showOrderDia ? (
                <React.Fragment key={10}>
                    <Modal
                        aria-labelledby="Bestill brøyting"
                        aria-describedby="Bestill brøyting"
                        className={classes.modal}
                        open={state.showOrderDia}
                        onClose={(e) => setState({...state, showOrderDia: !state.showOrderDia})}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={state.showOrderDia}>
                            <div className={classes.paper}>
                                <Card className="sc-card" key={11}>
                                    <CardHeader title={orderHeader} />
                                    <CardContent className="sc-cardContent" style={{alignContent: "center"}}>                
                                        <DateRange
                                            onChange={item => setOrderDates([item.selection])}
                                            ranges={orderDates}
                                            minDate={new Date()}
                                            weekStartsOn={1}
                                            months={2}
                                            direction="horizontal"
                                            locale={locales[locale]}
                                            dateDisplayFormat="d.MMM.yyyy"
                                            editableDateInputs={true}
                                        />
                                        <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={cabin.ownerId} onClick={(e) => saveScOrder(e)} > Bestill </Button>
                                    </CardContent>
                                    <Button variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
                                </Card>
                            </div>
                        </Fade>
                    </Modal>
                </React.Fragment>
            ):(
                <Button variant="contained" color="primary" type="submit" fullWidth id="btn" style={{margin: "5px"}} value={cabin.id} onClick={(e) => setState({...state, showOrderDia: !state.showOrderDia})} > Bestill brøyting </Button>
            );


            // When a cabin has been selected, show cabin information
            if(cabin.id === orderContext.selectedCabinId) {  
                return (
                    [basicPart, smsPart, orderPart]
                ) 
            } else {
                // else, show nothing from this section
                return null
            }          
        })
    ) : (
      null  
    );

    // Cabin action setup - NEW with Modal dialog
    const smsMessageField = (state.content==='CABINS' && orderContext.selectedCabinId===0 ) ? (
        state.showSmsAll  ? (
            <React.Fragment key={1}>
                <Modal
                    aria-labelledby="Send SMS til alle hyttene"
                    aria-describedby="Send SMS til alle hyttene"
                    className={classes.modal}
                    open={state.showSmsAll}
                    onClose={(e) => setState({...state, showSmsAll: !state.showSmsAll})}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={state.showSmsAll}>
                        <div className={classes.paper}>
                            <Card className="sc-card" key={2}>
                                <CardHeader title="Send SMS til alle hyttene" />
                                <CardContent className="sc-cardContent" style={{alignContent: "center"}}>                
                                    <TextField
                                        id="smsToAll"
                                        label="SMS melding"
                                        placeholder=""
                                        multiline
                                        rows="4"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => handleSMSTextInput(e)}
                                        value={state.smsMessage}
                                    />
                                    <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={1} onClick={(e) => handleSendSMSToAllClick(e)} > Send SMS </Button>
                                </CardContent>
                                <Button variant="contained" color="secondary" type="submit" fullWidth id="clearBtn" onClick={(e) => handleClearClick(e)} > Tilbake </Button>
                            </Card>
                        </div>
                    </Fade>
                </Modal>
            </React.Fragment>
        ) : (
            <Button variant="contained" color="primary" type="submit" fullWidth id="btn" value={1} style={{margin: "5px"}} onClick={(e) => setState({...state, showSmsAll: !state.showSmsAll})} > SMS til alle </Button>
        )
    ) : (
        null
    )

    // Arrival list - details
    const filteredList = orderContext.orderList.filter(order => {return order.fromDate == orderContext.selectedDate});
    const sortedList = filteredList.sort((a,b) => (a.fromTime>b.fromTime) ? 1 : -1);
    const arrivalList = (orderContext.selectedOrderId===0 && orderContext.selectedDate && orderContext.orderList.length && sortedList.length>0 && state.content==='ORDERS') ? (
        sortedList.map(order => {
            return <React.Fragment key={order.id}>{order.fromTime+" - "+order.address}<br /></React.Fragment>
            })
    ) : (
        <p> Ingen ankomster </p>
    );

    // Arrival list - frames
    const arrivalFrame = (orderContext.selectedOrderId===0 && orderContext.selectedDate && orderContext.orderList.length && state.content==='ORDERS') ? (
        <Card className="sc-card" key={-1}>
            <CardContent className="sc-cardContent">
                <p><b className="sc-head">Ankomster {orderContext.selectedDate}</b><br/>
                    {arrivalList}
                </p>
            </CardContent>
        </Card>
    ) : (
        null
    ); 

    // Blockage lines
    const blockLines = (state.cabinLayerGroup) ? (
        orderContext.blockLines.map(bline => {
            const line = splitt(bline.coordinates)
            var polyline = L.polyline(line, {color: 'red'}).addTo(state.cabinLayerGroup);
        })
    ) : (
        null
    )

    const clubList = (userContext.clubs.length>0) ? (
        userContext.clubs.map(club => {
            return (
                <MenuItem key={club.id} value={club.id}>{club.clubName}</MenuItem>
            )
        })
    ) : (<MenuItem value={0}>Ingen tilgjengelige</MenuItem>)

    const clubSelection = (userContext.clubs.length>0) ? (
        <div >
      <FormControl>
        <InputLabel id="club-select-label">Hytteforening</InputLabel>
        <Select
          labelId="club-select-label"
          id="club-select"
          value={userContext.currentClubId}
          onChange={handleSelectClub}
          align="left"
          style={{width: "200px"}}
        >
          {clubList}
        </Select>
      </FormControl>        
      </div>
    ):(
        null
    )


    //
    // Render the screen
    //
    // Page JSX setup - this is the core of it all
    return ( 
        <div id="mainContainer">
            <div id="sc-orderlist">
                <div id="selections">
                    <Typography variant="subtitle2" style={{backgroundColor: "lightGrey"}} >{userContext.companyName}</Typography>
                    {clubSelection}
                    <FormControlLabel 
                        label="Vis posisjon"
                        labelPlacement="start"                    
                        control={
                            <Checkbox checked={state.showLocation} size='small' color='primary' onChange={handleShowLocationChange} />
                        } 
                    /> 
                    <FormControl component="fieldset">
                        <RadioGroup defaultValue={state.content} value={state.content} aria-label="content" name="constentRadio" onChange={handleContentChange}>
                            <FormControlLabel value="ORDERS" size="small" control={<StyledRadio />} label="Vis bestillinger" />
                            <FormControlLabel value="CABINS" size="small" control={<StyledRadio />} label="Vis hytter" />
                        </RadioGroup>
                    </FormControl>
                    {dayButtons}
                </div>
                <div className="details" key={9}>
                    {orderMarkers}
                    {arrivalFrame}
                    {cabinMarkers}
                    {blockLines}
                    {smsMessageField}
                    <NavFooter drawerWidth="100%" />
                </div>
            </div>
            <div id="snowClearingMap"></div>
        </div>
    )

}

export default SnowClearingMap;

function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="primary"
        {...props}
      />
    );
  }


  const useStyles = makeStyles(theme => ({
  //const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '20%',
      width: 12,
      height: 12,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      //backgroundColor: '#137cbd',
      //backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    //  '&:before': {
    //    display: 'block',
    //    width: 10,
    //    height: 10,
        //backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    //    content: '""',
    //  },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },      
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },    
  }));
  