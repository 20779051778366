import getClubId from './InitializeClubInfo'
import { NO_ACCESS } from '../../components/auth/checkAccess';

const initState = {
    clubId: null,
    jwt: null,
    authError: null,
    signedIn: false,
    userId: null,
    userInitials: null,
    userAccess: NO_ACCESS
}

function setInitialBaseState() {
    initState.clubId = getClubId();
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            console.log('SUCCESS', action);
            return {
                ...state,
                authError: null,
                signedIn: true,
                jwt: action.data.token,
                userId: action.data.id,
                userInitials: action.data.initials,
                userAccess: action.data.accessLevel
            }
        case 'LOGIN_ERROR':
            console.log('ERROR', action.err);
            return {
                ...state,
                authError: 'Login failed',
                signedIn: false,
                jwt: null,
                userId: null,
                userInitials: null,
                userAccess: NO_ACCESS
            }
        default:
            setInitialBaseState();
            return state;
    }  
}

export default authReducer;