import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext'
import axios from 'axios'
import { Paper, Typography, TextField, Button } from '@material-ui/core'

const StartPage = () => {
    const {userContext, userDispatch} = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

  /////////////////////
  // Server interaction
  /////////////////////

    const signIn = (credentials) => {
        axios.post(global.gConfig.SERVER_URL+'/api/service/login', { 
            'headers': { 
                    'Content-Type': 'application/json' 
                    },
            //clubId: clubId,
            user: credentials.username,
            password: credentials.password
        })
        .then(res => {
            if(res.status===201) {
                console.log(res.data)
                userDispatch({type: 'LOGIN_SUCCESS', data: res.data})
            }
        })
        .catch((err) => {
            console.log('Error', err);
            userDispatch({type: 'LOGIN_ERROR', err: err})
        })        
    };      

  ////////////////////
  // useEffect area
  ////////////////////
  
  
  ////////////////////
  // Action handlers
  ////////////////////
  
  const handleUsername = (e) => {
    setUsername(e.target.value);
    } 

    const handlePassword= (e) => {
        setPassword(e.target.value);
    } 

    const handleSubmit = (e) => {
        e.preventDefault();
        signIn({username, password});
    }


  ////////////////////
  // UI part
  ////////////////////

    const renderRedirect = () => {
        //console.log(userContext)
        if (userContext.signedIn) {
            return <Redirect to='/snowClearMap' />
        }
      }
    
    const authFailed = () => {
        if(!userContext.signedIn && userContext.authError) {
            return (
                <div style={{padding:"20px"}}>
                    <Typography variant="subtitle1" style={{color:"red"}}>Feil brukernavn eller passord</Typography>
                </div>
            )
        }
    }

    return(
        <div>
            {renderRedirect()}
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <div style={{width:"300px", height:"300px", margin:"20px", padding: "20px"}}>
            <Paper elevation={6} style={{}}>
                <form onSubmit={handleSubmit} className="white paddedForm">
                <div style={{justifyContent:"center"}}>

                </div>
                    <Typography variant="h6">Innlogging</Typography>
                    <div style={{margin:"10px"}}>
                        <TextField
                            required margin="dense" id="user" label="Brukernavn" name="username"
                            fullWidth    
                            onChange={handleUsername}      
                        />                      
                        <TextField
                            required margin="dense" id="password" label="Passord" name="password"
                            fullWidth    
                            type="password"
                            onChange={handlePassword}      
                        />   
                    </div>

                    <div style={{padding: "10px"}}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Login</Button>     
                    </div>

                </form>
                {authFailed()}
            </Paper>

            </div>
            </div>

        </div>
    )        
}

export default StartPage;

/*

                   <div className="input-field">
                        <label htmlFor="user">Brukernavn</label>
                        <input type="text" id="user" onChange={handleUsername} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Passord</label>
                        <input type="password" id="password" onChange={handlePassword} />
                    </div>
                    <div className="input-field">
                        <button className="btn pink ligthen-1 z-depth-0">Login</button>
                    </div>

*/