const orderSort = (orderList) => {
  return orderList.sort(function(a,b) {
      if(a.completed < b.completed)
          return -1
      if(a.completed> b.completed)
          return 1
      return 0
  });
}

const OrderReducer = (state, action) => {
  switch(action.type) {
      case 'COMPLETE_ORDER':
        let newOrderList = state.orderList.filter(( order ) => {
          if(order.id == action.order.id) {
              order.completed = 1;
          }
          return order;
        })           
        return {
            ...state,
            orderList: orderSort(newOrderList)
        }        
      case 'SET_SELECTED_ORDER':
        return {
          ...state,
          selected: action.orderId,
          selectedOrderId: action.orderId
        }
      case 'SET_SELECTED_DATE':
        return {
          ...state,
          selectedDate: action.selectedDate
        }     
      case 'GET_WEEK_LIST':
        return {
          ...state,
          days: action.days
        }
      case 'DATA_LOAD_FAILED':
        return {
            ...state,
            dataLoadFailed: action.dataLoadFailed
        }        
      case 'GET_ALL_ORDERS': 
        return {
            ...state,
            orderList: orderSort(action.orders)
        }   
      case 'SET_CABINS':
        return {
          ...state,
          cabinList: action.cabins
        }        
      case 'SET_SELECTED_CABIN':
        return {
          ...state,
          selectedCabinId: action.cabinId
        }
      case 'SET_SC_BLOCK_LINES':
        return {
          ...state,
          blockLines: action.cabins
        }
      default:
        return state;
  }
}

export default OrderReducer;
