import axios from 'axios';

// Mark a snow clearing order as completed
export const completeOrder = (clubId, order, userContext, dispatch) => {
  let orderId = order.id;
  axios.patch(global.gConfig.SERVER_URL+'/api/snowClearingOrders/confirm/'+clubId+'/'+orderId,
      {
          order: order
      },            
      {
          'headers': { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+userContext.jwt
          },
      })
      .then(res => {
          orderId = res.data.orderId;
          dispatch({ type: 'COMPLETE_ORDER', order: order});
      })
      .catch(error => {
          console.log(error)
      });
};    


// GET snow clearing orders for the selected date
export const getOrders = (clubId, date, userContext, dispatch) => {
  let orders = [];
  axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/'+clubId+'/'+date, { 
      'headers': { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+userContext.jwt
          },
      })
      .then(res => {
          orders = res.data;
          //console.log("Update order list");
          dispatch({ type: 'GET_ALL_ORDERS', orders: orders });
      });
};


// Get the overview for the comming week
export const getWeekList = (clubId, userContext, dispatch) => {
  let days = [];
  axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/weekOverview/'+clubId, { 
      'headers': { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+userContext.jwt
          },
      })
      .then(res => {
          days = res.data;
          dispatch({ type: 'GET_WEEK_LIST', days: days });
      })
      .catch(error => {
          dispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true });
      });
};

// Get all cabins
export const getAllCabins = (clubId, userContext, dispatch) => {
    axios.get(global.gConfig.SERVER_URL+'/api/properties/sc/'+clubId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+userContext.jwt
            },
        })
        .then(res => {
            dispatch({ type: 'SET_CABINS', cabins: res.data });
        })
        .catch(error => {
            dispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true });
        });    
};


// Send SMS to one cabin
export const sendSmsToOne = (clubId, propertyId, message, userContext) => {
    axios.post(global.gConfig.SERVER_URL+'/api/properties/sc/sms/single/'+clubId+'/'+propertyId, {
            message: message,
        },
        { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+userContext.jwt
            }            
        })
        .then (res => {
            //console.log('Result - ',res);
            if(res.status===201) {
                //console.log('Success', res.status);
            }
          })
        .catch((err) => {
            console.log('Error', err);
        })         
};


// Send SMS to all cabins
export const sendSmsToAll = (clubId, message, userContext) => {
    axios.post(global.gConfig.SERVER_URL+'/api/properties/sc/sms/all/'+clubId, {
            message: message,
        },
        { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+userContext.jwt
            }
        })
        .then (res => {
            //console.log('Result - ',res);
            if(res.status===201) {
                //console.log('Success', res.status);
            }
          })
        .catch((err) => {
            console.log('Error', err);
        })         
};


// Place an order for snowclearing
export const orderSnowClearing = (clubId, order, userContext) => {
    axios.post(global.gConfig.SERVER_URL+'/api/snowClearingOrders/sc/create/'+clubId, {
            order: order,
        },
        { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+userContext.jwt
            }
        })
        .then (res => {
            //console.log('Result - ',res);
            if(res.status===201) {
                //console.log('Success', res.status);
            }
          })
        .catch((err) => {
            console.log('Error', err);
        })         
};

export const getAllScBlockLines = (clubId, userContext, dispatch) => {
    axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/map/block/sc/all/'+clubId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+userContext.jwt
            },
        })
        .then(res => {
            //console.log(res.data)
            dispatch({ type: 'SET_SC_BLOCK_LINES', cabins: res.data });
        })
        .catch(error => {
            dispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true, dataType: "Blocklines" });
        });    
}

// Given the operator that is logged in, get all clubs
export const getAllClubs = (companyId, userContext, userDispatch, orderDispatch) => {
    axios.get(global.gConfig.SERVER_URL+'/api/snowClearingOrders/clubs/get/all/'+companyId, { 
        'headers': { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+userContext.jwt
            },
        })
        .then(res => {
            console.log(res.data)
            userDispatch({ type: 'SET_CLUBS', clubs: res.data });
            userDispatch({ type: 'SET_CURRECT_CLUB', clubId: res.data[0].id });
        })
        .catch(error => {
            orderDispatch({ type: 'DATA_LOAD_FAILED', dataLoadFailed: true, dataType: "Clubs" });
        });    
}