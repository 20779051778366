const clubDefault = 1;

export default function() {
    let clubId = clubDefault;
    let url = window.location.href;
    let ind = url.lastIndexOf('siteId');
    if(ind>-1) {
        clubId = url.substring(url.lastIndexOf('siteId')+7, url.length);
    } 
    return clubId;
}

