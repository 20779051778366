import clubReducer from './clubReducer'
import authReducer from './authReducer'
import snowClearingReducer from './snowClearingReducer'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    auth: authReducer,
    club: clubReducer,
    snow: snowClearingReducer
})

export default rootReducer;
