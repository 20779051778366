import React from 'react'

const NavFooter = ({drawerWidth}) => {

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////

  ///////////
  // UI part
  ///////////

  const FIXED_TEXT = " FjellSys leveres av Bryggesoft AS";
  const link = "http://fjellsys.no/";
  const text = (<a key={1} style={{textDecoration: "none"}} href={link} target="_blank">{FIXED_TEXT}</a>)
  
  return (
      <div key={0} style={{position: "fixed", fontSize: 10, textAlign:"center", bottom: "0px", height: 15, backgroundColor: "WhiteSmoke"}}>
        <span style={{marginLeft: "30px", marginBottom: "5px"}}>{text}</span> 
      </div>
    )
  }

export default NavFooter;
