import getClubId from './InitializeClubInfo'

const initState = {
    clubId: 1,
    clubBasics: null,
    clubDetails: null,
    clubBoard: {},
    clubConfig: {}
}

function setInitialBaseState() {
    initState.clubId = getClubId();
}

const clubReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_CLUB_DETAILS': 
            return {
                ...state,
                clubBasics: action.clubBasics
            }
        case 'GET_CLUB_BOARD':
            return {
                ...state,
                clubBoard: action.clubBoard
            }
        case 'GET_CLUB_CONFIG':
            return {
                ...state,
                clubConfig: action.clubConfig
            }
        default: 
            setInitialBaseState();
            return state;
    }
}

export default clubReducer;