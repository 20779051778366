import { NO_ACCESS, OPERATOR_ACCESS } from '../components/auth/checkAccess';

const UserReducer = (state, action) => {
  switch(action.type) {
      case 'LOGIN_SUCCESS':
        //console.log(action.data)
        return ({
          ...state,        
          userId: action.data.id,
          name: action.data.name,
          username: action.data.username,
          companyId: action.data.companyId,
          companyName: action.data.companyName,
          authError: null,
          signedIn: true,
          jwt: action.data.token,
          userAccess: OPERATOR_ACCESS
        })
      case 'LOGIN_ERROR':
        return ({
          ...state,
          authError: 'Login failed '+action.err,
          signedIn: false,
          jwt: null,
          userId: 0,
          name: '',
          username: '',
          companyId: 0,
          companyName: '',
          userAccess: NO_ACCESS,
          clubs: [], 
          currentClubId: 0        
        })
      case 'SET_CLUBS':
        return ({
          ...state,
          clubs: action.clubs
        })
      case 'SET_CURRECT_CLUB':
        return ({
          ...state,
          currentClubId: action.clubId
        })
      /*case 'SET_CLUB_DETAILS': 
        return {
          ...state,
          clubBasics: action.clubBasics
        }*/        
      default:
          return state;
  }
}

export default UserReducer;
