const initState = {
    orderList: [],
    selected: 0,
    days: [],
    selectedDate: null,
    selectedOrderId: 0,
    dataLoadFailed: false
}

const orderSort = (orderList) => {
    return orderList.sort(function(a,b) {
        if(a.completed < b.completed)
            return -1
        if(a.completed> b.completed)
            return 1
        return 0
    });
}

const snowClearingReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_ORDERS': 
            return {
                ...state,
                orderList: orderSort(action.orders)
            };
        case 'COMPLETE_ORDER':
                let newOrderList = state.orderList.filter(( order ) => {
                    if(order.id == action.order.id) {
                        order.completed = 1;
                    }
                    return order;
                });            
            return {
                ...state,
                orderList: orderSort(newOrderList)
            };
        case 'SET_SELECTED_DATE':
            return {
                ...state,
                selectedDate: action.selectedDate
            };
        case 'SET_SELECTED_ORDER': 
            //console.log('Set selected - reducer:',action.orderId);
            return {
                ...state,
                selected: action.orderId,
                selectedOrderId: action.orderId
            };
        case 'GET_WEEK_LIST':
            return {
                ...state,
                days: action.days
            };
        case 'DATA_LOAD_FAILED':
            return {
                ...state,
                dataLoadFailed: action.dataLoadFailed
            }
        default: 
            return state;
    }
}

export default snowClearingReducer;