import React, { createContext, useReducer } from 'react'
import UserReducer from '../reducers/UserReducer'
import { NO_ACCESS } from '../components/auth/checkAccess';

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const [userContext, userDispatch] = useReducer(UserReducer , {
      userId: 0,
      name: '',
      username: '',
      companyId: 0,
      companyName: '',
      authError: null,
      signedIn: false,
      jwt: null,
      userAccess: NO_ACCESS,
      currentClubId: 0,
      clubs: []
    });

    return (
        <UserContext.Provider value={{ userContext, userDispatch }}>
            {props.children}
        </UserContext.Provider>
    ); 
}

export default UserContextProvider;