
//
// Function to splitt up the string for describing parking area into an array of positions
//
export const splitt = (str) => {
  let result = [];
  let arr = str.split('#');
  arr.map(pair => {
    let duo = pair.split(',');
    let point = [duo[0], duo[1]];
    result.push(point);
  })
  return result;
}    

// Same as above, but also splits into multiple elements (arrays)
// Splits position arrays by &
export const multiSplitt = (str) => {
  if(str) {
    let result = [];
    let arr = str.split('&');
    //console.log(arr);
    // If only one object, no need to spilt 
    if(arr.length<=1) {
      return splitt(str);
    } else {
      arr.map(obj => {
        result.push(splitt(obj))
      })
    }
    //console.log(result)
    return result;  
  } 
  return str;
}

//
// Function to format date and time
//
export const getTimeFormat = (datestr) => {
  let dt = new Date(datestr);
  return ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear() + ' ' + ('0'+(dt.getHours()-1)).slice(-2)+':'+('0'+dt.getMinutes()).slice(-2);
}

//
// Function to format date 
//
export const getDateFormat = (datestr) => {
  let dt = new Date(datestr);
  return ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear();
}

