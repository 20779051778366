export const NO_ACCESS = 0;
export const OPERATOR_ACCESS = 10;
export const ADMIN_ACCESS = 99;


/*export function checkAccess(functionId, userAccessLevel) {
    switch(functionId) {
        // Create messages
        case 100: {
            let access = [ADMIN_ACCESS, LEADER_ACCESS, ACCOUNTANT_ACCESS, SECRETARY_ACCESS, BOARD_MEMBER_ACCESS, TRACK_RESP_ACCESS];
            return access.includes(userAccessLevel);
        } 
        // Message overview / delete function
        case 101: {
            let access = [ADMIN_ACCESS, LEADER_ACCESS, ACCOUNTANT_ACCESS, SECRETARY_ACCESS, BOARD_MEMBER_ACCESS, TRACK_RESP_ACCESS];
            return access.includes(userAccessLevel);
        } 
        // Maintain property details
        case 201: {
            //console.log(functionId, userAccessLevel);
            let access = [ADMIN_ACCESS, LEADER_ACCESS, ACCOUNTANT_ACCESS, SECRETARY_ACCESS, BOARD_MEMBER_ACCESS];
            return access.includes(userAccessLevel);
        }
        default : 
            return false;
    }
}*/
