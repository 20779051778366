// Load config first
import './config/config'

import React, { Component } from 'react'
import UserContextProvider from './contexts/UserContext'
import OrderContextProvider from './contexts/OrderContext'
import { BrowserRouter, Route } from 'react-router-dom'
import StartPage from './components/StartPage'
import SnowClearingMap from './components/SnowClearingMap'
import Pos from './components/Pos'
//import MiniDrawer from './components/MiniDrawer'
//import Nav from './components/Nav'

class App extends Component {
  
  render() {  
    return (
      <UserContextProvider>
        <OrderContextProvider>
          <BrowserRouter>
            <main>
              <div className="App">
                <Route exact path='/' render={(props) => <StartPage {...props} siteId='1' />} />       
                <Route exact path='/p' render={(props) => <Pos {...props} />} />       
                <Route path='/snowClearMap' component={SnowClearingMap} />
                <Route path='/startPage' render={(props) => <StartPage {...props} siteId='1' />} />       
              </div>
            </main>
          </BrowserRouter>
        </OrderContextProvider>
      </UserContextProvider>
    )
  }
}

export default App;