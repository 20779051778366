import React, {useState, useEffect, useContext} from 'react'
import L from 'leaflet'

const Pos = () => {

    // Local state
    const [state, setState] = useState({
      mapHolder: null,
      cabinLayerGroup: null,
      tractorLayerGroup: null,
      zoom: 16,
      showLocation: true,
      screenRefreshRate: 5000,
  });

  // Tractor marker
  let tractorIcon = L.icon({
    iconUrl: require("../images/circleMarker.png"),
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [-3, -76]
  });

  //
  // Show GPS location
  // Called from the map.watch function triggered 
  //
  const onLocationFound = (e) => {
    let radius = e.accuracy/2;
    console.log(radius, e.latlng, state.tractorLayerGroup);
    if(e.latlng && state.tractorLayerGroup) {
      state.tractorLayerGroup.clearLayers();
      //L.marker(e.latlng, {icon: tractorIcon}).addTo(state.tractorLayerGroup).bindPopup("snowClearingMap");
      //L.circle(e.latlng, radius).addTo(state.tractorLayerGroup);
      L.circle(e.latlng, 0.3).addTo(state.tractorLayerGroup);
    }
  }  
  const onLocationError = (e) => {
    console.log(e.message);
  }



  useEffect(() => {

        // Map is not initialized ==> Initialize map 
        // Setup map - this creates the map instance

        //let map = L.map('snowClearingMap').setView([state.lat, state.lng], state.zoom);
        let map = L.map('snowClearingMap').setView([59.43087, 5.2835479], 20);
        L.tileLayer('https://opencache2.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}', {
                    attribution: '<a href="http://www.kartverket.no/">Kartverket</a>',
                    maxZoom: 20
                }).addTo(map);
        let tractorLayerGroup = L.layerGroup().addTo(map);

        // Save map and layers to local state for convenience
        setState({
            ...state,
            mapHolder: map,
            //cabinLayerGroup: cabinLayerGroup,
            tractorLayerGroup: tractorLayerGroup
        });
  }, [])

    useEffect(() => {
      console.log(state.tractorLayerGroup)
      // Use GeoLocation / GPS position
      if(state.mapHolder) {
        //map.locate({watch: true, setView: true, maximumAge: 5000 });
        state.mapHolder.on('locationfound', onLocationFound);
        state.mapHolder.on('locationerror', onLocationError);      
        state.mapHolder.locate({watch: true, setView: true, timeout:1000, maximumAge: 0, enableHighAccuracy: true });
      }
    }, [state.mapHolder, state.tractorLayerGroup])


    // Page JSX setup - this is the core of it all
  return (
    <div id="snowClearingMap"></div>
  )

}

export default Pos;